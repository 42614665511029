@use "../../config/" as *;

.waf-cricketscorecard{ 
    @extend %p-zero;
    .waf-head{
        max-width: var(--container-max-width);
        margin: 0 auto;
        @extend %w-100;
       .title{
            @extend %m-t-zero;
            @extend %m-b-one-and-quarter;
            @extend %capitalize;
       }
    }
    .card{
        &-section{
            position: relative;
            background-color: var(--body-text-color);
            @include bottomShape(null, 4rem);
            @extend %m-x-half-neg;
            @extend %p-y-one-and-quarter;
            @extend %p-x-full;
            &::after{
                content: "";
                width: calc(100% - 3.4rem);
                height: 0.3rem;
                background-color: var(--match-center-extra-run);
                @include position-style(null, null, null, 0, 0); 
            }
        }
        &-wrap{
            @extend  %flex-column-n-n;
            .progress-wrap{
                @extend %d-none;
            }
        }
        &-header{
            border-bottom: 0.1rem solid rgba(var(--grey-color-rgb), 0.15);
            @extend %flex-sb-c;
            @extend %p-b-one-and-quarter;
        }
        &-item{
            color: var(--white-color);
            .head-wrap{
               width: 62%;
            }
            .and-txt{
                @extend %d-none;
            }
            .title{
                text-transform: unset;
                @include font(12, null, $font-medium);
            }
            .matchinfo{
                text-transform: unset;
                @include font(12, null, $font-medium);
            }
            .match-time{
                text-transform: unset;
                @include font(12, null, $font-medium);
            }
            .text{
                @include font(11, null, $font-regular);
            }
            .status{
                background-color: rgba(var(--white-color-rgb), 0.3);
                @include font(11, null, $font-medium);
                @extend %flex-n-c;
                @extend %p-x-three-fourth;
                @extend %p-y-quarter;
            }
            .score{
                display: block;
                @include font(22, null, $font-bold);
                @extend %text-right;
            }
            .si-overs{
                display: block;
                @include font(11, null, $font-regular);
                @extend %text-right;
                em{
                    font: inherit;
                }
            }
            .scorecard-innings-wrap{
                color: rgba(var(--white-color-rgb), 0.5);
                border-right: 0.1rem solid rgba(var(--grey-color-rgb), 0.15);
                @extend %p-r-three-fourth;
                @extend %m-r-three-fourth;
                &:first-child{
                    border-right: 0;
                    @extend %p-r-zero;
                    @extend %m-r-zero;
                }
                &:nth-child(2), &:nth-child(3){
                    .score{
                        @include font(16, 21, $font-bold);
                    }
                    .si-overs{
                        @include font(10, null, $font-regular);
                        em{
                            font: inherit;
                        }
                    }
                   } 
            }
            .team{
                @extend %flex-sb-c;
                @extend %m-b-full;
                &:last-of-type{
                    @extend %m-b-zero;
                }
                &-info{
                    @extend %flex-n-c;
                }
                &-score{
                    @include flex-config(flex, row-reverse, null, center);
                    &.current, &.won{
                        .score{
                            color: var(--recent-color);
                        }
                    }
                }
                &-logo{
                    width: 5rem;
                    aspect-ratio: 1/1;
                    background-color: var(--white-color);
                    @extend %circle-radius;
                    @extend %p-quarter;
                    @extend %m-r-full;
                }
                &-name{
                    @include font(18, null, $font-bold);
                }
                &-status{
                    display: block;
                    @include font(12, null, $font-regular);
                    @extend %m-b-one-and-quarter;
                }
            }

            .end-match-footer{
                order: 1;
                border-top: 0.1rem solid rgba(var(--grey-color-rgb), 0.15);
                @extend %flex-n-c;
                @extend %p-t-full;
                .player-name{
                    @include font(14, 15, $font-bold);
                    @extend %uppercase;
                    @extend %m-l-quarter;
                }
            }
            .player{
                &-details{
                    @extend %m-b-three-fourth;
                    .label{
                        background-color: rgba(var(--black-color-rgb), 0.3);
                        @include font(12, null, $font-regular);
                        @extend %flex-n-c;
                        @extend %p-y-half;
                        @extend %p-x-one-and-quarter;
                        @extend %m-b-three-fourth;
                        @extend %capitalize;
                        &::before{
                            content: "\e86c";
                            color: var(--white-color);
                            @include font(15, 18, "waf-font-icon");
                            @extend %m-r-half;
                        }
                    }  
                    &.batsman-details{
                        .label{
                            &::before{
                                content: "\e86c"; 
                            }
                        }
                    }
                    &.bowler-details{
                        .label{
                            &::before{
                                content: "\e86d";
                            }
                        }
                    }
                }
                &-info{
                    @extend %p-x-one-and-quarter;
                    @extend %flex-sb-c;
                }
                &-name{
                    color: var(--white-color);
                    @include font(12, 26, $font-bold);
                }
                &-score{
                    color: var(--white-color);
                    @include font(12, 26, $font-bold);
                }
            }
            .runs-detail{
                border-top: 0.1rem solid rgba(var(--grey-color-rgb), 0.15);
                @extend %flex-n-c;
                @extend %p-t-full;
                .label{
                    @include font(11, 14, $font-regular);
                }
            }
            .run{
                position: relative;
                width: 2.7rem;
                aspect-ratio: 1/1;
                background-color: var(--white-color);
                color: var(--black-color);
                overflow: visible;
                @include font(11, 14, $font-bold);
                @extend %flex-c-c;
                @extend %circle-radius;
                @extend %p-quarter;
                @extend %m-x-quarter;
                @extend %uppercase;
                span{
                    @include font(11, 14, $font-bold);
                    @extend %uppercase;
                }
                &:first-of-type{
                    @extend %m-l-three-fourth;
                }
                &-info{
                    @extend %flex-n-c;
                }
                &.four{
                    background-color: var(--match-center-runs);
                    color: var(--white-color);
                }
                &.six{
                    background-color: var(--kabaddi-primary-color);
                    color: var(--body-text-color);
                }
                &.wicket{
                    background-color: var(--match-center-balls);
                    color: var(--white-color);
                }
                .over-extra-score{
                    width: 1.5rem;
                    height: 1.5rem;
                    flex-shrink: 0;
                    aspect-ratio: 1/1;
                    background-color: var(--match-center-extra-run);
                    @include position-style(null, -0.8rem, -0.5rem, null, null);
                    @include font(11, 24, $font-regular);
                    @extend %circle-radius;
                    @extend %flex-c-c;
                }
            }
            &.live{
                .status{
                    position: relative;
                    background-color: var(--live-color);
                    @extend %p-l-one-and-quarter;
                    &::before{
                        content: "";
                        width: 1.3rem;
                        height: 1.3rem;
                        background-image: url("/static-assets/images/cssimages/svg/live.svg?v=#{$image-version}");
                        background-repeat: no-repeat;
                        @include position-style(y-center, null, null, null, var(--quarter-space));         
                    }
                }
            }
            &.upcoming{
                min-height: calc(26rem - calc(var(--one-and-quarter-space) * 2));
                .status{
                    background-color: rgba(var(--match-center-label-rgb), 0.3);
                }
                .team-status{
                    @extend %w-100;
                    @extend %m-zero;
                }
            }
        }
        &-content{
            position: relative;
            &::after{
                content: unset;
                width: 2.7rem;
                aspect-ratio: 1/1;
                color: var(--body-text-color);
                background-color: rgba(var(--white-color-rgb), 0.8);
                border-radius: 50%;
                @include font(14, null, $font-medium);
                @include position-style(center, null, null, null, null);
                @include flex-config(flex, null, center, center);
            }
            @extend %m-b-three-fourth;
            @extend %p-y-full;
        }  
    }
} 

@media (min-width: $tablet-min-width){
    .waf-cricketscorecard{ 
        margin-top: 4rem;
        .card{
            &-section{
                background-image: url("/static-assets/images/cssimages/mast-head-bg-web.png?v=#{$image-version}");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                &::after{
                    height: 0.4rem;
                }
            }
            &-item{
                width: var(--container-max-width);
                margin: 0 auto;
                .score{
                    margin-right: 1rem;
                    @include font(30, 39, $font-bold);
                    &.yet-to-bat{
                        @include font(30, 39, $font-bold);
                    }
                }
                .si-overs{
                    @include font(14, null, $font-regular);
                }
                .scorecard-innings-wrap{
                    padding-bottom: 0.5rem;
                    border-bottom: 0.1rem solid rgba(var(--grey-color-rgb), 0.15);
                    margin-bottom: 0.5rem;
                    padding-right: 0;
                    margin-right: 0;
                    @include flex-config(flex, null, null, center);
                    &:last-child{
                        border-bottom: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                    &:nth-child(2), &:nth-child(3){
                        border-right: 0;
                        .score{
                            margin-right: 0.5rem;
                            @include font(11, 16, $font-bold);
                        }
                        .si-overs{
                            @include font(11, 16, $font-regular);
                        }
                       } 
                }
                .team{
                    margin-bottom: 0;
                    flex-basis: calc(50% - 3.5rem);
                    width: calc(50% - 3.5rem);
                    &-score{
                        @include flex-config(flex, column, null, flex-start);
                    }
                    &-logo{
                        width: 7rem;
                    }
                    &-status{
                        text-align: center;
                        border-top: 0;
                    }
                    &-name{
                        width: 14rem;
                        margin-right: var(--full-space);
                        @include font(16, 22, $font-bold);
                    }
                    &.team-a{
                        padding-right: calc(var(--full-space) + 2rem);
                        @include flex-config(flex, row, flex-end, center);
                        .team-info{
                            text-align: right;
                            @include flex-config(flex, row-reverse, null, center);
                        }
                    }
                    &.team-b{
                        padding-left: calc(var(--full-space) + 2rem);
                        @include flex-config(flex, row-reverse, flex-end, center);
                        .team-name{
                            margin-right: 0
                        }
                        .team-score{
                            margin-right: var(--full-space);
                        }
                    }
                }
                .innings-info{
                    @include flex-config(flex, null, null, null);
                }
                .player{
                    &-details{
                        flex-grow: 1;
                        &.batsman-details{
                            margin-right: var(--half-space);
                        }
                        &.bowler-details{
                            margin-left: var(--half-space);
                        }
                    }
                }
                &.upcoming{
                    min-height: calc(20rem - calc(var(--one-and-quarter-space) * 2));
                }
                &.super-over{
                    .scorecard-innings-wrap{
                        &:last-of-type{
                            border-bottom: 0.1rem solid rgba(var(--grey-color-rgb), 0.15);
                        }
                    }
                }
            }
            &-content{
                border-bottom: 0.1rem solid rgba(var(--grey-color-rgb), 0.15);
                &::after{
                    content: "VS";
                }
                @include flex-config(flex, null, center, center);
            }  
        }
    }   
}
@media (min-width: $tablet-min-width) and (max-width: $desktop-min-width){
    .waf-cricketscorecard{ 
        .card{
            &-item{
                .score{
                    margin-right: 1rem;
                    @include font(22, null, $font-bold);
                     &.yet-to-bat{
                        @include font(12, null, $font-bold);
                    }
                }
                .si-overs{
                    @include font(11, null, $font-regular);
                }
                .team{
                    &-logo{
                        width: 5rem;
                    }
                }
                &.upcoming{
                    min-height: calc(18rem - calc(var(--one-and-quarter-space) * 2));
                }
            }
        }
    } 
}
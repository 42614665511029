@use "../../config/" as *;

.waf-cricketscorecard{
    .scorecard-container{
        max-width: var(--container-max-width);
        margin: auto;
        position: relative;
        &::before{
            content: "";
            background: url("/static-assets/images/cssimages/match-center-bg-mob.png?v=#{$image-version}");
            background-size: contain;
            width: var(--window-inner-width);
            height: 100%;
            z-index: -1;
            @include position-style(null, 0, null, null, calc((var(--window-inner-width) - var(--container-max-width)) / 2 * -1)); 
        }
        .tabs{
            overflow: auto;
            margin: 0 var(--half-space-negative);
            .tab-name{
                flex: 1;
                min-width: 10rem;
                @include transition(.3s);
                &:not(.tab-active){

                    &:hover{
                        color: inherit;
                        font: inherit;
                        border: 0;
                        &::before{
                            display: none;
                        }
                    }
                }
            }
        }
        .tab-container{
            .tabs{
                .fullname{
                    @extend %d-none;
                }
                .short-name{
                    display: block;
                }
            }
        }
    }
}

@media (min-width: $tablet-min-width){
    .waf-cricketscorecard{
        .scorecard-container{
            &::before{
                background: url("/static-assets/images/cssimages/match-center-bg-web.png?v=#{$image-version}");
            }
            .tab-container{
                .tabs{
                    .fullname{
                        display: block
                    }
                    .shortname{
                        display: none;
                    }
                }
            }
        }
    }
}
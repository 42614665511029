@use "../../config/" as *;


.waf-cricketscorecard {
    .commentary-tab-container {

        p,
        .commentary-text {
            @include font(14, null, $font-regular)
        }

        .text-section,
        .content-section,
        .highlight-section {
            padding: 0 var(--full-space)
        }

        .sub-title,
        .title,
        .highlight-title,
        .commentary-title {
            @include font(16, null, $font-bold);
        }

        .commentary-title {
            margin-top: 0;
        }

        .over-extra-score {
            width: 1.5rem;
            height: 1.5rem;
            background-color: var(--accent-color);
            @include font(10);
            @include position(-1rem, -1rem, null, null);
            @include border-radius(100%);
            @extend %flex-c-c;
        }

        .over {
            &-details {
                position: relative;
                flex-shrink: 0;
                text-align: center;
                @extend %m-l-full;
                @extend %m-r-full;
            }
            &-count{
                width: 100%;
                @include font(12);
            }
            &-score {
                width: 4rem;
                height: 4rem;
                background-color: var(--grey-color);
                position: relative;
                @include font(16, null, $font-bold);
                @extend %flex-c-c;
                @extend %uppercase;
            }

            &-wrap {
                @extend %flex;
            }

            &-info {

                .btn-site {
                    margin: var(--full-space) auto;
                    display: inherit;
                    @include font(14, null, $font-bold);

                    .btn-text {
                        @include font(14, null, $font-bold);
                    }
                }

                .head {
                    margin: var(--full-space) 0 0;
                    padding: var(--half-space) var(--full-space);
                    background-color: var(--body-text-color);
                    color: var(--white-color);

                    &-wrap {
                        @extend %flex-sb-c;
                    }
                }

                .over-text {
                    @include font(12, null, $font-regular);
                    &.score{
                        
                        @include font(12, null, $font-bold);
                    }
                }
            }



            &-item {
                padding: calc(var(--full-space) + var(--half-space)) 0;
                border-bottom: 0.1rem solid rgb(var(--black-color-rgb) , 0.2);

                &.over-removed {
                    .commentary-title {
                        &::before {
                            width: 0;
                        }
                    }
                }

                &.four{

                    .over-score {
                        color: var(--white-color);
                        background-color: var(--match-center-runs);
                    }
                }
                &.six {
                    .over-score {
                        background-color: var(--accent-color);
                        color: var(--white-color);
                    }
                }

                &.wicket {
                    .over-score {
                        background-color: var(--lost-color);
                        color: var(--white-color);
                    }
                }
            }
        }

        .nomatch-section{
            @extend %m-t-full;
        }
    }
}

@media (min-width: $tablet-min-width) {
    .waf-cricketscorecard {

        .scorecard-container {
            margin: 0;
        }

        .tab-container {

            >.commentary-tab-container,
            >.scorecard-tab-container,
            >.graph-tab-container,
            >.matchinfo-tab-container {
                background-color: var(--page-background-color);
            }
        }

        .fixtures-tab,
        .scorecard-tab {
            .tab-name {
                width: calc(100% / 4);
                flex: 1;
            }
        }
    }
}
@use "../../config/" as *;

.waf-cricketscorecard {
    .scorecard-tab-container{
        .team-score-detail{
            @extend %flex-sb-c;
        }
        .batting-scorecard{
            @extend %m-t-full;
        }
        .bowling-scorecard{
            @extend %m-t-full;
        }
        .detail-part1{
            @extend %flex-n-c;
        }
        .team-flag{
            img{
               width: 4.5rem;
               aspect-ratio: 1/1;
               object-fit: contain;
               background-color: var(--match-center-scorecard-img);
               @include box-shadow(0 0.2rem 0.2rem 0 rgba(var(--black-color-rgb), 0.12));
               @extend %circle-radius;
               @extend %p-quarter;
               @extend %m-r-half;
            }
        }
        .team-name{
            color: var(--match-center-team);
            @include font(14, null, $font-bold);
            @extend %uppercase;
            &.fullname{
                @extend %d-none;
            }
        }
        .runs, .over-runrate{
            @include font(11, null, $font-bold);
        }
        .overs{
            @include font(11, null, $font-regular);
        }
        .table{
            &-head{
                .table-row{
                    background-color: var(--match-center-team);
                }
                .table-data{
                    span{
                        color: var(--white-color);
                        @include font(12, null, $font-bold);
                    }
                    &.label{
                        &::before{
                            content: "\e86c";
                            color: var(--white-color);
                            @include font(16, 16, "waf-font-icon");
                            @extend %m-r-quarter;
                        }
                    }
                }
            }
            &-row{
                border-bottom: 0.1rem solid rgba(var(--body-text-color-rgb), 0.1);
                @extend %flex-n-c;
                @extend %p-y-three-fourth;
                @extend %p-x-full;
                &:last-of-type{
                    border-bottom: 0;
                }
            }
            &-data{
                flex-basis: 13%;
                max-width: 13%;
                @extend %text-center;
                &.label{
                    flex-basis: 35%;
                    max-width: 35%;
                    @extend %text-left;
                }
                span{
                    @include font(12, null, $font-medium);
                }
                .name{
                    @include font(12, null, $font-bold);
                }
                .status{
                    display: block;
                    @include font(11, null, $font-regular);
                }
            }
            &.batting-table{
                .table-head{
                    .table-data{
                        &.label{
                            &::before{
                                content: "\e86c";
                            }
                        }
                    }
                }
            }
            &.wicket-table{
                .table-head{
                    .table-data{
                        &.label{
                            &::before{
                                content: "\e86e";
                            }
                        }
                    }
                }
            }
            &.bowling-table{
                .table-head{
                    .table-data{
                        &.label{
                            &::before{
                                content: "\e86d";
                            }
                        }
                    }
                }
                .table-data{
                    &.no-ball{
                        @extend %d-none;
                    }
                    &.wide-ball{
                        @extend %d-none;
                    }
                }
            }
        }
        .extras-scorecard{
            color: var(--white-color);
            background-color: var(--match-center-team);
            margin-bottom: calc(var(--full-space) * 2); 
            @extend %p-y-three-fourth;
            @extend %p-x-full;
            span{
                @include font(12, null, $font-bold);
            }
        }
        .wicket-scorecard{
            .table{
                .label{
                    flex-basis: 80%;
                    max-width: 80%;
                }
                .overs{
                    flex-basis: 20%;
                    max-width: 20%;
                }
                .fall-wicket-run{
                    @extend %m-r-one-and-quarter;
                }
            }
        }
    }
    .sub-container-wrap{
        @extend %m-t-full;
    }
    .tab-container{
        margin-bottom: calc(var(--full-space) * 4);
    }
}

@media (min-width: $tablet-min-width){
    .waf-cricketscorecard {
        .scorecard-tab-container{
            .runs, .over-runrate{
                @include font(12, null, $font-bold);
            }
            .overs{
                @include font(12, null, $font-regular);
            }
            .team-flag{
                img{
                   margin-right: var(--full-space);
                }
            }
            .team-name{
                &.fullname{
                    display: block
                }
                &.shortname{
                    display: none;
                }
            }
            .table{
                &-head{
                    .table-data{
                        span{
                            @include font(14, null, $font-bold);
                        }
                        &.label{
                            &::before{
                                margin-right: var(--three-fourth-space);
                                @include font(18, 18, "waf-font-icon");
                            }
                        }
                    }
                }
                &-row{
                    padding: 1.5rem 2rem;
                }
                &-data{
                    span{
                        @include font(14, null, $font-medium);
                    }
                    .name{
                        @include font(14, null, $font-bold);
                    }
                }
                &.bowling-table{
                    .table-data{
                        &.no-ball, &.wide-ball{
                            display: block
                        }
                    }
                }
            }
            .extras-scorecard{
                span{
                    @include font(14, null, $font-bold);
                }
            }
        }
    }
}
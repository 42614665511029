@use "../../config/" as *;

@use "../../config/" as *;

.waf-cricketscorecard {
   
    .matchinfo-tab-container {
        margin: 0;
        background-color: var(--white-color);
        padding: var(--full-space);
        margin: 0 0 var(--full-space) 0;

        .matchinfo-list{
            .matchinfo-item{
                &:last-of-type{
                    .info{
                        @extend %uppercase;
                    }
                }
            }
        }
        .matchinfo-item {
            padding: var(--full-space);
            @include flex-config(flex,null,space-between,center);

            &:not(:last-child) {
                border-bottom: .1rem solid rgba(var(--black-color-rgb),0.2);
            }

        }

        .info {
            text-align: right;
            width: 45%;
            @include font(12,null,$font-medium);
            
            
            &-label {
                @include font(12,null,$font-medium);


            }

        }
    }
}

@media (min-width:$tablet-min-width) {
    .waf-cricketscorecard {
        .matchinfo-tab-container {
            background-color: var(--white-color);
            .matchinfo-item{
                padding: var(--full-space);
            }
            .info{
                text-align: left;

                @include font(14);
            }
            .info-label {
                width: 19rem;
                position: relative;
                margin-right: 1rem;
                @include font(14);
               
            }

            .info {
                width: calc(100% - 20rem);
                text-align: left;
            }
        }
    }
}